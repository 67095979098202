import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
//@ts-ignore
import LogoText from "../assets/svg/logoText.svg";
//@ts-ignore
import MenuDots from "../assets/svg/menuDots.svg";
import useGlobalStateContext, { Language } from "../context/GlobalContext";
import breakpoints from "../styles/breakpoints";
import colors from "../styles/colors";
import { scaleFromSize } from "../styles/globalStyles";
import { DesktopMenu } from "./menu";

const Header = styled.header`
  background: ${colors.background.darkGreen};
  color: ${colors.text.white};

  position: sticky;
  top: 0;

  padding: 20px var(--BASE_GUTTER);
  width: 100%;

  transition: color ease-out 0.2s;

  @media ${breakpoints.tablet} {
    padding: 20px 32px;
  }

  @media ${breakpoints.tabletLandscape} {
    position: absolute;
    padding: 28px 32px;
  }

  rect,
  path {
    fill: ${colors.text.white};
    fill: ${colors.text.white};
    transition: fill ease-out 0.2s;
  }

  .no-scroll & {
    transition: none;
    background: ${colors.background.darkGreen};
    color: ${colors.text.white};

    rect,
    path {
      transition: none;
      fill: ${colors.text.white};
    }
  }

  z-index: 20;

  > svg {
    width: 106px;

    @media ${breakpoints.tablet} {
      width: 150px;
    }
  }

  > h2 {
    text-transform: uppercase;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;

  > a {
    display: flex;
  }
`;

const MobileMenuButton = styled.nav`
  align-self: center;
  cursor: pointer;

  @media ${breakpoints.tabletLandscape} {
    display: none;
  }
`;

export const StyledLogo = styled(LogoText)`
  width: 106px;

  @media ${breakpoints.tablet} {
    width: 150px;
  }
`;

const LanguagePickerWrapper = styled.div`
  display: none;
  text-transform: uppercase;
  font-size: 2.2rem;
  line-height: 1;

  @media ${breakpoints.tablet} {
    display: flex;
    margin-top: 45px;

    font-size: ${scaleFromSize(62)};
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: 2.6rem;
    margin-top: 0;
  }
`;

const LanguageToggle = styled.span<{ currentLanguage?: boolean }>`
  cursor: pointer;
  opacity: ${(props) => (props.currentLanguage ? 1 : 0.3)};
`;

export const LanguagePicker = ({ className }: { className?: string }) => {
  const { language, setLanguage } = useGlobalStateContext();
  return (
    <LanguagePickerWrapper className={className}>
      <LanguageToggle
        currentLanguage={language === Language.SWEDISH}
        onClick={() => setLanguage(Language.SWEDISH)}
      >
        SE{" "}
      </LanguageToggle>
      <LanguageToggle
        currentLanguage={language === Language.ENGLISH}
        onClick={() => setLanguage(Language.ENGLISH)}
      >
        EN
      </LanguageToggle>
    </LanguagePickerWrapper>
  );
};

export default ({
  className,
  location,
}: {
  className?: string;
  location: Location;
}) => {
  const { menuOpen, setMenuOpen } = useGlobalStateContext();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Header className={className}>
      <Wrapper>
        <Link to="/">
          <StyledLogo />
        </Link>

        <MobileMenuButton onClick={toggleMenu}>
          <MenuDots />
        </MobileMenuButton>
        <DesktopMenu location={location} />
      </Wrapper>
    </Header>
  );
};
