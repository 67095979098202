import styled, { createGlobalStyle, css } from "styled-components";
import breakpoints from "./breakpoints";
import { deviceSizes } from "../deviceSizes";
import colors from "./colors";

// After large desktop screen we want to scale some stuff by viewport size
export const scaleFromSize = (baseSizeInPixels: number) => {
  const sizeInVw = (baseSizeInPixels / deviceSizes.maxWidth) * 100;
  return `${sizeInVw}vw`;
};

export const h2Styles = `
  font-family: "Johnston", Arial, Helvetica, sans-serif;
  font-size: 2.8rem;

  @media ${breakpoints.tablet} {
    font-size: 5.4rem;
  }
`;

export const h3Styles = `
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2.8rem;

  @media ${breakpoints.tablet} {
    font-size: ${(24 / deviceSizes.tablet) * 100}vw;
  }

  // @media ${breakpoints.maxWidth} {
  //   font-size: ${scaleFromSize(36)};
  // }
`;

export const preambleStyles = `
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.8rem;

  @media ${breakpoints.tablet} {
    font-size: 2.2rem;
  }
`;

export const bodyTextStyles = `
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
`;

export const imageTransition = `
  transition: transform 0.2s ease-in;
`;

export const gridStyles = `
  display: grid;
  grid-column-gap: var(--BASE_GUTTER);
  grid-template-columns: repeat(12, minmax(20px, 1fr));
  
  margin: 0 auto;
  max-width: 1300px;
`;

export const basePadding = `
  padding: var(--BASE_GUTTER);
`;

export const baseGrid = `
  ${basePadding};
  display: flex;
  flex-wrap: wrap;

  @media ${breakpoints.tablet} {
    ${gridStyles};
    padding: 0 32px;
  }

  @media ${breakpoints.maxWidth} {
    padding: 0;
  }
`;

export default createGlobalStyle`
  :root {
    --BASE_GUTTER: 25px;

    @media ${breakpoints.tablet} {
      --BASE_GUTTER: 12px;
    }
  }

  html {
    font-size: 10px;

    @media (min-width: ${deviceSizes.tablet}px) and (max-width: ${
  deviceSizes.maxWidth
}px) {
  font-size: ${scaleFromSize(10)};
  }

  body {
    margin: 0;
    font-family: "Fournier", 'Times New Roman', Times, serif;
    -webkit-font-smoothing: antialiased;

    &.no-scroll {
      overflow: hidden;
    }

    /* @media ${breakpoints.tablet} {
      font-size: 2rem;;
    } */

    * {
      box-sizing: border-box;
    }
  }

  h1, h2, h3, ul, ol, p, blockquote {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
  }

  
  p, li {
    line-height: 1.15;
  }

  p {
    font-size: 1.8rem;
    
    @media ${breakpoints.tablet} {
      font-size: 2.6rem;
    }
  }
  
  li {
    font-size: 2.0rem;
    
    @media ${breakpoints.tablet} {
      font-size: 2.6rem;
    }
  }

  ol {
    padding-inline-start: var(--BASE_GUTTER);
  }

  h2, h3, blockquote {
    font-weight: 300;
  }

  h1, h2, h3 {
    font-family: "Johnston", Arial, Helvetica, sans-serif;
  }

  h2 {
    ${h2Styles}
  }

  h3 {
    font-size: 3.4rem;

    @media ${breakpoints.tablet} {
      font-size: 4.5rem;
    }
  }

  ul {
    list-style: none;
  };

  a,
  a:visited,
  a:active {
    text-decoration: none;
    color: inherit;
  }
`;

export enum svgColor {
  WHITE = "white",
  PINK = "pink",
  DARKGREEN = "darkgreen",
}

const dotSvg = (color: svgColor) => {
  let fillColor;
  if (color === svgColor.PINK) {
    fillColor = "%23FF9188";
  } else if (color === svgColor.DARKGREEN) {
    fillColor = "%231f3e25";
  } else {
    fillColor = "%23ffffff";
  }

  return `%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='2' viewBox='0 0 2 2'%3E%3Ccircle id='Ellipse_5' data-name='Ellipse 5' cx='1' cy='1' r='1' fill='${fillColor}'/%3E%3C/svg%3E%0A`;
};

export const dottedBorder = (color?: svgColor) => css`
  position: relative;

  &:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 2px;
    background-image: url("data:image/svg+xml, ${dotSvg(
      color || svgColor.DARKGREEN
    )}");
    background-repeat: repeat-x;
    bottom: 0.1em;

    @media ${breakpoints.tablet} {
      bottom: 0.15em;
    }
  }
`;

export const dottedLink = css`
  ${dottedBorder()};

  &.white {
    &:before {
      background-image: url("data:image/svg+xml, ${dotSvg(svgColor.WHITE)}");
    }
  }

  &:hover {
    color: ${colors.text.pink};

    &:before {
      background-image: url("data:image/svg+xml, ${dotSvg(svgColor.PINK)}");
    }
  }
`;

export const Grid = styled.div`
  ${baseGrid}
`;

export const breakOutOfGutter = `
  margin-left: calc(var(--BASE_GUTTER) * -1);
  margin-right: calc(var(--BASE_GUTTER) * -1);
`;
