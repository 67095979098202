import React, { forwardRef } from "react";
import styled from "styled-components";
import colors from "../styles/colors";

// @ts-ignore
import LogoText from "../assets/svg/logoText.svg";
// @ts-ignore
import SplashText from "../assets/svg/splashText.svg";
import breakpoints from "../styles/breakpoints";

const SplashScreen = styled.div`
  background: ${colors.background.darkGreen};
  color: ${colors.text.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* No idea why I can't use MOBILE_HEADER_HEIGHT here... */
  min-height: calc(100vh - 75px);

  @media ${breakpoints.tablet} {
    min-height: unset;
  }
`;

const StyledLogoText = styled(LogoText)`
  height: 100%;
  width: 80%;
  max-width: 100%;
  margin-top: 45px;

  @media ${breakpoints.tablet} {
    width: 690px;
  }
`;

export default forwardRef<HTMLDivElement>(({ className }, ref) => {
  return (
    <SplashScreen ref={ref} className={className}>
      <SplashText />
      <StyledLogoText />
    </SplashScreen>
  );
});
