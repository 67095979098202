import React, {
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
  createContext,
  useContext,
} from "react";

export enum Language {
  SWEDISH = "svSE",
  ENGLISH = "enUS",
}

interface StateContext {
  menuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  hasDisplayedSplash: boolean;
  setHasDisplayedSplash: Dispatch<SetStateAction<boolean>>;
  language: Language;
  setLanguage: Dispatch<SetStateAction<Language>>;
}

const GlobalStateContext = createContext<StateContext>({
  menuOpen: false,
  setMenuOpen: () => {},
  hasDisplayedSplash: false,
  setHasDisplayedSplash: () => {},
  language: Language.SWEDISH,
  setLanguage: () => {},
});

const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [hasDisplayedSplash, setHasDisplayedSplash] = useState(false);
  const [language, setLanguage] = useState(Language.SWEDISH);

  const state = {
    menuOpen,
    setMenuOpen,
    hasDisplayedSplash,
    setHasDisplayedSplash,
    language,
    setLanguage,
  };

  return (
    <GlobalStateContext.Provider value={state}>
      {children}
    </GlobalStateContext.Provider>
  );
};

const useGlobalStateContext = () => useContext(GlobalStateContext);

export { GlobalProvider };
export default useGlobalStateContext;
