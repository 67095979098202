exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-employee-tsx": () => import("./../../../src/pages/employee.tsx" /* webpackChunkName: "component---src-pages-employee-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-karriar-tsx": () => import("./../../../src/pages/karriar.tsx" /* webpackChunkName: "component---src-pages-karriar-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-medarbetare-tsx": () => import("./../../../src/pages/medarbetare.tsx" /* webpackChunkName: "component---src-pages-medarbetare-tsx" */),
  "component---src-pages-om-oss-tsx": () => import("./../../../src/pages/om-oss.tsx" /* webpackChunkName: "component---src-pages-om-oss-tsx" */),
  "component---src-pages-vara-tjanster-tsx": () => import("./../../../src/pages/vara-tjanster.tsx" /* webpackChunkName: "component---src-pages-vara-tjanster-tsx" */)
}

