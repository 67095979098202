import { useLayoutEffect, useCallback, useState } from "react";
import { deviceSizes } from "./../deviceSizes";

const getScreenSize = (screenWidth?: number) => {
  const { tablet, tabletLandscape } = deviceSizes;

  const isMobile = screenWidth && screenWidth < tablet;
  const isTablet = screenWidth && !isMobile && screenWidth < tabletLandscape;
  const isDesktop = !isMobile && !isTablet;

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};

export default () => {
  const getWindowSize = useCallback(() => {
    return typeof document === "undefined"
      ? undefined
      : document.documentElement.clientWidth;
  }, []);

  const [screenSizes, setScreenSizes] = useState(() =>
    getScreenSize(getWindowSize())
  );

  useLayoutEffect(() => {
    const resizedScreenSize = () => {
      const screenWidth = getWindowSize();
      const sizes = getScreenSize(screenWidth);
      setScreenSizes(sizes);
    };

    window.addEventListener("resize", resizedScreenSize);

    return () => {
      window.removeEventListener("resize", resizedScreenSize);
    };
  }, []);

  return {
    ...screenSizes,
    screenWidth: getWindowSize(),
  };
};
