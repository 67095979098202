import React, { ReactNode } from "react";
import Helmet from "react-helmet";
import styled, { css, keyframes } from "styled-components";

import GlobalStyles from "../styles/globalStyles";
import { MobileMenu } from "../components/menu";
import Header from "../components/header";
import Footer from "../components/footer";

import useGlobalStateContext, {
  GlobalProvider,
} from "../context/GlobalContext";
import CompanyInfo from "../components/companyInfo";
import SplashScreen from "../components/splashScreen";

import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group";
import colors from "../styles/colors";
import breakpoints from "../styles/breakpoints";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

const timeout = 150;

const navigationStyles = {
  entering: {
    position: "absolute",
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-out`,
    opacity: 0.2,
  },
};

export const MOBILE_HEADER_HEIGHT = "75px";
export const DESKTOP_HEADER_HEIGHT = "93px";

const hideSplash = keyframes`
    0% {
      transform : translate3d(0, 0, 0)
    }
    100% {
      transform : translate3d(0, calc(-100% + ${MOBILE_HEADER_HEIGHT}), 0)
    }
`;
const hideDesktopSplash = keyframes`
    0% {
      transform : translate3d(0, 0, 0)
    }
    100% {
      transform : translate3d(0, calc(-100% + 2px), 0)
    }
`;

const ContentWrapper = styled.div`
  position: relative;
  padding-top: ${MOBILE_HEADER_HEIGHT};

  display: flex;
  flex-direction: column;
  flex-basis: content;
  flex-basis: max-content;
  min-height: 100vh;

  @media ${breakpoints.tabletLandscape} {
    padding-top: ${DESKTOP_HEADER_HEIGHT};
  }
`;

const StyledHeader = styled(Header)`
  @media ${breakpoints.tablet} {
    bottom: 2px;
    top: auto;
    transform: translate3d(0, 100%, 0);
    position: absolute;
  }
`;

const DesktopHeader = styled(Header)`
  display: none;

  @media ${breakpoints.tabletLandscape} {
    display: block;
  }
`;

const SplashWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 30;
  width: 100%;
  height: 100vh;

  transition: transform 1s;

  background: ${colors.background.darkGreen};

  animation-name: ${hideSplash};
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  @media ${breakpoints.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: center;

    animation-name: ${hideDesktopSplash};
  }
`;

const StyledTransitionGroup = styled(TransitionGroup)<{
  $contactpage?: string;
}>`
  flex: 1;

  ${(props) =>
    props.$contactpage &&
    css`
      background: ${colors.background.white};
    `}
`;

const insertPolyfill = () => {
  if (
    typeof window !== "undefined" &&
    (typeof window.IntersectionObserver === "undefined" ||
      typeof window.URLSearchParams === "undefined")
  ) {
    const script = document.createElement("script");
    script.src =
      "https://polyfill.io/v3/polyfill.min.js?features=IntersectionObserver";
    document.body.appendChild(script);
  }
};

export default ({
  children,
  location,
}: {
  children: ReactNode;
  showCompanyInfo?: boolean;
  showSplash?: boolean;
  location: Location;
}) => {
  const { hasDisplayedSplash, setHasDisplayedSplash } = useGlobalStateContext();
  insertPolyfill();

  const isContactpage = location.pathname.startsWith("/kontakt");
  const showCompanyInfo = !isContactpage;
  const startPage = location.pathname === "/";
  const showSplash = startPage && !hasDisplayedSplash;

  const {
    title: seoTitle,
    description: seoDescription,
    siteUrl,
  } = useSiteMetadata();

  return (
    <>
      <GlobalProvider>
        <GlobalStyles />
        <Helmet>
          <link rel="icon" href="/images/favicon.svg" />
          <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <title>Kane</title>
          <meta name="description" content={seoDescription} />
          <meta name="og:title" content={seoTitle} />
          <meta name="og:url" content={siteUrl} />
          <meta name="og:description" content={seoDescription} />
          <meta name="twitter:title" content={seoTitle} />
          <meta name="twitter:url" content={siteUrl} />
          <meta name="twitter:description" content={seoDescription} />
        </Helmet>

        <ContentWrapper>
          {showSplash && (
            <SplashWrapper onAnimationEnd={() => setHasDisplayedSplash(true)}>
              <SplashScreen />
              <StyledHeader location={location} />
            </SplashWrapper>
          )}
          <DesktopHeader location={location} />
          <MobileMenu location={location} />
          <StyledTransitionGroup $contactpage={isContactpage}>
            <ReactTransition key={location.pathname} timeout={timeout}>
              {(status) => (
                <div style={navigationStyles[status]}>
                  {children}
                  {showCompanyInfo ? <CompanyInfo /> : null}
                </div>
              )}
            </ReactTransition>
          </StyledTransitionGroup>
          <Footer />
        </ContentWrapper>
      </GlobalProvider>
    </>
  );
};
