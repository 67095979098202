export default {
  background: {
    // white: "#F7F6F0",
    white: "#F8F5ED",
    gray: "#3E3E3E",
    grayGreen: "#EDECE6",
    darkGreen: "#1F3E25",
    // darkGreen: "#023A00",
    sectionGreen: "#D8E0D7",
  },
  text: {
    // white: "#F5F4EF", // Ska det vara den här eller den andra?
    white: "#F8F5ED",
    darkGreen: "#1F3E25",
    // darkGreen: "#023a00",
    pink: "#FF9188",
  },
};
