import React from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";

import colors from "../styles/colors";
import { baseGrid, basePadding } from "../styles/globalStyles";
//@ts-ignore
import LinkedInLogo from "../assets/svg/linkedin.svg";
import breakpoints from "../styles/breakpoints";
import useGlobalStateContext from "../context/GlobalContext";

const FooterWrapper = styled.footer`
  background: ${colors.background.darkGreen};
  color: ${colors.text.white};

  @media ${breakpoints.tablet} {
    padding: 33px 0;
  }
`;

const FooterContent = styled.div`
  ${baseGrid};
  padding-bottom: calc(var(--BASE_GUTTER) * 2);
  justify-content: space-between;

  text-align: center;
  align-items: center;

  @media ${breakpoints.tablet} {
    padding-bottom: 0;
  }
`;

const StyledLinkedInLogo = styled(LinkedInLogo)`
  fill: ${colors.text.white};
  width: 37px;
  height: 37px;

  @media ${breakpoints.tablet} {
    width: 64px;
    height: 64px;
  }
`;

const LinksWrapper = styled.div`
  grid-column: 7 / -1;
  text-align: left;
  font-size: 1.8rem;

  @media ${breakpoints.tablet} {
    font-size: 2.6rem;
    margin-top: 0;
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: 2rem;
    margin-top: 0;
  }
`;

interface ContentfulResponse {
  contentfulAllmannaVillkor: {
    generalTermsInSwedish: {
      file: File;
    };
    generalTermsInEnglish: {
      file: File;
    };
  };
  contentfulPersonuppgiftspolicy: {
    privacyPolicyInSwedish: {
      file: File;
    };
  };
}

export default () => {
  const { language } = useGlobalStateContext();
  const { contentfulAllmannaVillkor, contentfulPersonuppgiftspolicy } = query();

  const terms = {
    heading: language === "svSE" ? "Allmänna villkor" : "Terms and Condition",
    url: contentfulAllmannaVillkor[language]?.file?.url,
  };

  const privacyNotice = {
    heading: language === "svSE" ? "Personuppgiftspolicy" : "Privacy Notice",
    url: contentfulPersonuppgiftspolicy[language]?.file?.url,
  };

  return (
    <FooterWrapper>
      <FooterContent>
        <a
          href="https://se.linkedin.com/company/advokatfirmankane"
          target="_blank"
        >
          <StyledLinkedInLogo />
        </a>
        <LinksWrapper>
          <Link to={terms.url} target="_blank">
            {terms.heading}
          </Link>{" "}
          |{" "}
          <Link to={privacyNotice.url} download>
            {privacyNotice.heading}
          </Link>
        </LinksWrapper>
      </FooterContent>
    </FooterWrapper>
  );
};

function query() {
  return useStaticQuery(
    graphql`
      query GetDownloadableFiles {
        contentfulAllmannaVillkor {
          svSE: generalTermsInSwedish {
            file {
              url
            }
          }
          enUS: generalTermsInEnglish {
            file {
              url
            }
          }
        }
        contentfulPersonuppgiftspolicy {
          svSE: privacyPolicyInSwedish {
            file {
              url
            }
          }
          enUS: privacyPolicyInEnglish {
            file {
              url
            }
          }
        }
      }
    `
  );
}
