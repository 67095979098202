import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { basePadding, dottedLink, Grid } from "../styles/globalStyles";
import colors from "../styles/colors";
import { MenuLinks } from "./menu";
import breakpoints from "../styles/breakpoints";
import useBreakpoint from "../hooks/useBreakpoint";

const CompanyInfoWrapper = styled.div`
  background: ${colors.background.white};
  color: ${colors.text.darkGreen};
  width: 100%;
  margin-top: 25px;

  @media ${breakpoints.tablet} {
    padding: 60px 0 175px;
  }
`;

const CompanyName = styled.span`
  grid-column: 1 / span 4;
  font-family: "Johnston", Arial, Helvetica, sans-serif;
  font-size: 2.2rem;

  @media ${breakpoints.tablet} {
    font-size: 3.5rem;
  }
`;

const CompanyInfo = styled.ul`
  width: 100%;
  justify-content: space-between;
  margin-top: 65px;
  white-space: pre-wrap;
  display: flex;

  @media ${breakpoints.tablet} {
    display: block;
    margin-top: 0;
    grid-column: 7 / span 2;
    font-size: 2.6rem;
  }
`;

const LinkWrapper = styled.ul`
  grid-column: 10 / -1;
  margin-top: var(--BASE_GUTTER);

  li {
    margin-top: 6px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  a {
    font-size: 2.6rem;
    display: inline-block;
    ${dottedLink};
  }

  @media ${breakpoints.tablet} {
    margin-top: 0;
  }
`;

export interface CompanyInfoProps {
  companyName: string;
  companyAddress: {
    companyAddress: string;
  };
}

interface ContenfulResponse {
  companyInfo: CompanyInfoProps;
  reception: Employee;
}

export default ({
  className,
  showMenuItems = true,
}: {
  className?: string;
  showMenuItems?: boolean;
}) => {
  const queryResult: ContenfulResponse = useStaticQuery(
    graphql`
      query CompanyInformation {
        companyInfo: contentfulForetagetsKontaktuppgifter {
          companyName
          companyAddress {
            companyAddress
          }
        }
        reception: contentfulAnstalld(name: { eq: "Reception" }) {
          name
          email
          mobilePhoneNumber
          officePhoneNumber
        }
      }
    `
  );
  const { isMobile } = useBreakpoint();
  const { companyName, companyAddress } = queryResult.companyInfo;
  const { email, mobilePhoneNumber, officePhoneNumber } = queryResult.reception;

  const showMenuLinks = !isMobile && showMenuItems;

  return (
    <CompanyInfoWrapper className={className}>
      <Grid>
        <CompanyName>{companyName}</CompanyName>
        {isMobile ? (
          <CompanyInfo>
            <li>{companyAddress.companyAddress}</li>
            <li>
              <a href={`mailto:${email}`}>{email}</a>
              <br />
              <a href={`tel:+${mobilePhoneNumber}`}>{mobilePhoneNumber}</a>
              <br />
              <a href={`tel:+${officePhoneNumber}`}>{officePhoneNumber}</a>
            </li>
          </CompanyInfo>
        ) : (
          <>
            <CompanyInfo>
              <li>{companyAddress.companyAddress}</li>
              <li>
                <a href={`mailto:${email}`}>{email}</a>
              </li>
              <li>
                <a href={`tel:${mobilePhoneNumber}`}>{mobilePhoneNumber}</a>
              </li>
              <li>
                <a href={`tel:${officePhoneNumber}`}>{officePhoneNumber}</a>
              </li>
            </CompanyInfo>

            {showMenuLinks && (
              <LinkWrapper>
                <MenuLinks />
              </LinkWrapper>
            )}
          </>
        )}
      </Grid>
    </CompanyInfoWrapper>
  );
};
