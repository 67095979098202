import React, { useEffect } from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";
import colors from "../styles/colors";

import breakpoints from "../styles/breakpoints";
import useGlobalStateContext from "../context/GlobalContext";
import Header, { LanguagePicker, StyledLogo } from "./header";
import { MOBILE_HEADER_HEIGHT } from "../layouts";
import Cross from "../assets/svg/cross.svg";
import { dottedBorder, scaleFromSize, svgColor } from "../styles/globalStyles";

const MenuContainer = styled.div<{
  show: boolean;
}>`
  display: flex;
  background: ${colors.background.darkGreen};
  color: ${colors.text.white};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;

  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  z-index: 20;
  transform: ${(props) =>
    props.show
      ? "translate3d(0, 0, 0)"
      : `translate3d(0, calc(-100% + ${MOBILE_HEADER_HEIGHT}), 0)`};
  transition: transform 0.8s ease-in-out;

  @media ${breakpoints.tabletLandscape} {
    display: none;
  }
`;

const Menu = styled.nav`
  width: 100%;

  li {
    text-align: center;
    cursor: pointer;
    margin-top: 30px;

    font-size: 3.5rem;

    &:first-of-type {
      margin-top: 0;
    }

    a:active {
      position: relative;
      ${dottedBorder(svgColor.WHITE)};
    }

    @media ${breakpoints.tablet} {
      margin-top: 45px;

      font-size: ${scaleFromSize(62)};
    }
  }
`;

const DesktopMenuWrapper = styled.nav`
  display: none;
  font-size: 2.6rem;
  width: 100%;

  > ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex: 1;
  }

  @media ${breakpoints.tabletLandscape} {
    display: flex;
    padding-top: 5px;
  }
`;

const TermLinks = styled.div`
  margin-bottom: 30px;
  width: auto;
  text-align: center;
  width: 100%;

  font-size: 1.8rem;

  li {
    &:last-of-type {
      margin-top: 10px;
    }
  }

  @media ${breakpoints.tablet} {
    display: flex;
    justify-content: center;
    font-size: ${scaleFromSize(40)};

    li {
      &:last-of-type {
        margin-top: 0;
      }
    }
  }
`;

const MobileMenuTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px var(--BASE_GUTTER);
  width: 100%;

  @media ${breakpoints.tablet} {
    padding: 20px 32px;
  }
`;

const StyledCross = styled(Cross)`
  width: 22px;
  align-self: flex-end;
  margin-bottom: 12px;
`;

const StyledLanguagePicker = styled(LanguagePicker)`
  display: flex;
`;

export const MobileMenu = ({ location }: { location: Location }) => {
  const { menuOpen, setMenuOpen } = useGlobalStateContext();

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [menuOpen]);

  const handleAnchorLink = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <MenuContainer show={menuOpen}>
        <MobileMenuTopWrapper>
          <Link to="/" onClick={() => setMenuOpen(false)}>
            <StyledLogo />
          </Link>
          <StyledCross onClick={() => setMenuOpen(false)} />
        </MobileMenuTopWrapper>

        <Menu>
          <MenuLinks location={location} />
        </Menu>

        <StyledLanguagePicker />

        <TermLinks>
          <Link to="/allmanna-villkor/">Allmänna villkor</Link> |{" "}
          <Link
            to="/allmanna-villkor/#personuppgiftspolicy"
            onClick={handleAnchorLink}
          >
            Personuppgiftspolicy
          </Link>
        </TermLinks>
        <Header location={location} />
      </MenuContainer>
    </>
  );
};

const LinkWrapper = styled.li<{ active?: boolean }>`
  ${(props) =>
    props.active &&
    css`
      font-style: italic;
    `}
`;

export const MenuLinks = ({
  className,
  mobileMenu = true,
  location,
}: {
  className?: string;
  mobileMenu?: boolean;
  location: Location;
}) => {
  const { setMenuOpen, language } = useGlobalStateContext();

  const handleMenuClose = () => setMenuOpen(false);

  const links = [
    {
      menuName: language === "enUS" ? "About Us" : "Om oss",
      path: "/om-oss/",
    },
    {
      menuName: language === "enUS" ? "Our Services" : "Våra tjänster",
      path: "/vara-tjanster/",
    },
    {
      menuName: language === "enUS" ? "Our People" : "Medarbetare",
      path: "/medarbetare/",
    },
    {
      menuName: language === "enUS" ? "Career" : "Karriär",
      path: "/karriar/",
    },
    {
      menuName: language === "enUS" ? "Contact Us" : "Kontakt",
      path: "/kontakt/",
    },
  ];

  return (
    <ul className={className}>
      {links.map((menuLink, index) => {
        const { menuName, path } = menuLink;
        const activeLink = location?.pathname === path;
        return (
          <LinkWrapper
            active={activeLink}
            className={activeLink ? "active" : undefined}
            key={`menu-${index}`}
          >
            <Link to={path} onClick={mobileMenu ? handleMenuClose : undefined}>
              {menuName}
            </Link>
          </LinkWrapper>
        );
      })}
    </ul>
  );
};

const DesktopMenuLinks = styled(MenuLinks)`
  a {
    ${dottedBorder(svgColor.WHITE)};

    &:before {
      opacity: 0;
      transition: opacity 0.2s ease-in;
    }

    &:hover:before {
      opacity: 1;
    }
  }

  li {
    line-height: 1;

    &.active a:before {
      content: none;
    }
  }
`;

export const DesktopMenu = ({ location }: { location: Location }) => {
  return (
    <DesktopMenuWrapper>
      <DesktopMenuLinks location={location} />
      <LanguagePicker />
    </DesktopMenuWrapper>
  );
};
